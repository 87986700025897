import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <Header />
    <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    <Footer />
  </Layout>
);

export default IndexPage;
